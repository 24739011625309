<template>
<div @click="cancelAdd" style="position: relative; height: 100%;">
  <div v-if="disabledSidebar" class="sidebarOverlay"></div>
  <div class="ym-logo">
    <h1><router-link to="/" :style="{'background-image': `url(${storage?.content}${logo?.content})`}">SERVICEAPP</router-link></h1>
  </div>
  <aside class="menu ym-nav" v-if="dictionary && Object.keys(dictionary).length > 0">
      <ul class="menu-list">
          <router-link tag="li" class="mimp" to="/service"><a><icon name="file"></icon><span>Zlecenia serwisowe</span></a></router-link>
          <li class="mimp"><a style="cursor: initial"><icon name="layers"></icon><span>Kartoteki</span></a>
            <ul>
                <router-link tag="li" to="/records/customer"><a><span>Klienci</span></a></router-link>
                <router-link tag="li" to="/records/service-technician"><a><span>Serwisanci</span></a></router-link>
                <router-link tag="li" to="/records/machinery"><a><span>{{ dictionary['table_order_devices_type_field_label'] }}</span></a></router-link>
                <router-link tag="li" to="/records/operators"><a><span>{{ dictionary['table_order_operators_name_field_label'] }}</span></a></router-link>
                <router-link tag="li" to="/records/services"><a><span>{{ dictionary['table_order_is_services_field_label'] }}</span></a></router-link>
            </ul> 
          </li>
          <router-link tag="li"  class="mimp" to="/raports"><a><icon name="file-text"></icon><span>Raporty</span></a></router-link>

          <router-link tag="li"  class="mimp" style="margin-top: auto"  to="/settings/"><a><icon name="settings"></icon><span>Ustawienia</span></a></router-link>
          <li @click="logout" class="mimp red"><a><icon name="log-out"></icon><span>Wyloguj</span></a></li>
      </ul>
  </aside>
</div>

</template>


<script>
  import {mapActions, mapGetters} from 'vuex'
  import YMmodal from '@/components/Modal.vue';

  export default {
    name: 'Sidebar',
    data() {
      return {

      }
    },    
    computed: {
      ...mapGetters({
        disabledSidebar: 'disabledSidebar',
        storage: 'storage',
        logo: 'logo',
        dictionary: 'dictionary',
      })      
    },
    methods: {
      ...mapActions({
        authLogout: 'auth/authLogout'
      }),  
      logout: function () {
        this.authLogout().then(() => location.reload())
      },      
      cancelAdd() {
        if(this.disabledSidebar) {
          this.$buefy.modal.open({
              parent: this,
              component: YMmodal,
              hasModalCard: true,
              props: {
                title: 'Czy na pewno chcesz przerwać',
                content: '',
                cancel: 'Nie',
                ymHasIcon: true,
                ymIcon: 'x',
                submit: `<span class="mal50i">Przerwij</span>`
              }
          })
        }
      }
    }    
  }
</script>

<style lang="scss" scoped>
  .sidebarOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 34;
  }
</style>
